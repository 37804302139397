import React from "react";
import Col from "react-bootstrap/Col";
import Link from "./link";
import { GatsbyImage } from "gatsby-plugin-image";
import { FaLongArrowAltRight } from "react-icons/fa";
import gsap from "gsap";
import { IoIosArrowDropdownCircle } from "react-icons/io";

const CardFacialNew = ({
	image,
	title,
	text,
	url,
	imgAlt,
	imageHeight,
	headingHeight,
	shorter,
	objPos,
	span,
	imgCropHeight,
	idAnim,
	secondaryHeading,
	secondarySubheading,
	secondaryText,
}) => {
	const colSpan = span || 3;
	const newImgHeight = imgCropHeight || "400px";

	const enterAnimation = (text) => {
		gsap.to(
			text,

			{
				opacity: 1,
				display: "block",

				duration: 0.5,
			}
		);
	};
	const exitAnimation = (text) => {
		gsap.to(
			text,

			{ opacity: 0, display: "none", duration: 0.5 }
		);
	};

	return (
		<Col style={{ minHeight: "100%" }} className="" md={6} lg={5} xl={colSpan}>
			<div
				style={{
					boxShadow: "0px 3px 50px #00000029",
					background: "none",
					overflow: "hidden",
				}}
				className={` w-100 h-100 feature-box  pb-5 position-relative`}
			>
				<div
					style={{ zIndex: "5", opacity: 0, display: "none" }}
					className={`position-absolute w-100 h-100 bg-white px-4 pt-5 pt-lg-4 pt-xl-5 ${idAnim}`}
				>
					<IoIosArrowDropdownCircle
						onClick={() => exitAnimation(`.${idAnim}`)}
						style={{
							right: "1rem",
							top: "1rem",
						}}
						className={` fs-4 text-primary pointer scale-one position-absolute 
							`}
					/>

					<h3 className="fs-4 mb-4">{secondaryHeading}</h3>
					<h3 className="fs-5 mb-4 text-text-secondary">
						{secondarySubheading}
					</h3>
					{secondaryText}
					<div className="position-absolute start-50 translate-middle-x bottom-0 pb-4">
						<Link
							to={url}
							className="fs-5 d-flex align-items-center  justify-content-center pointer gold-link"
						>
							VIEW PAGE <FaLongArrowAltRight className="ms-2 fs-6" />
						</Link>
					</div>
				</div>
				<div className={`position-relative d-lg-none  `}>
					<div style={{ zIndex: 0, overflow: "hidden" }} className={`   `}>
						<GatsbyImage
							style={{ height: "350px" }}
							className=""
							image={image}
							alt={imgAlt}
							objectPosition={objPos}
						/>
					</div>
				</div>
				<div
					style={{ height: imageHeight }}
					className={`position-relative d-none d-lg-block ${shorter} `}
				>
					<div
						style={{ zIndex: 0, overflow: "hidden" }}
						className={` position-absolute treatment-card-image-container top-0 `}
					>
						<GatsbyImage
							style={{ height: newImgHeight }}
							image={image}
							alt={imgAlt}
							objectPosition={objPos}
						/>
					</div>
				</div>
				<div
					style={{ width: "calc(100% + 3px)", left: "-1px", zIndex: 2 }}
					className="bg-white  px-4 d-lg-none pt-lg-7 pt-5 position-relative text-center "
				>
					<h3 className="fs-4 text-text-secondary">{title}</h3>
					<p>{text}</p>

					<Link
						onClick={() => enterAnimation(`.${idAnim}`)}
						// to={url}
						className="fs-5 d-flex align-items-center pointer justify-content-center gold-link"
					>
						FIND OUT MORE <FaLongArrowAltRight className="ms-2 fs-6" />
					</Link>
				</div>
				<div
					style={{ width: "calc(100% + 3px)", left: "-1px", zIndex: 2 }}
					className="bg-white  px-4 d-none d-lg-block pt-7 position-relative text-center card-div"
				>
					<h3 className="fs-4 text-text-secondary">{title}</h3>
					<p>{text}</p>
					<Link
						onClick={() => enterAnimation(`.${idAnim}`)}
						// to={url}
						className="fs-5 d-flex align-items-center justify-content-center pointer gold-link"
					>
						FIND OUT MORE <FaLongArrowAltRight className="ms-2 fs-6" />
					</Link>
				</div>
			</div>
		</Col>
	);
};

export default CardFacialNew;
