import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../components/layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import ContactForm from "../../components/contactForm";
import CardFacialNew from "../../components/facial-new-card";
import { GatsbyImage } from "gatsby-plugin-image";
import { checkPropertiesForNull } from "../../utils/objectHelper";
import { SafeHtmlParser } from "../../components/safe-html-parser";
import uuid4 from "uuid4";

const FacialAestheticsTreatmentsPage = () => {
  const data = useStaticQuery(graphql`
    query {
      reviews {
        reviewCount
      }
      schemaLogo: wpMediaItem(title: { eq: "Logo-footer" }) {
        altText
        localFile {
          publicURL
          childImageSharp {
            original {
              height
              width
            }
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: TRACED_SVG
            )
          }
        }
      }
      site {
        siteMetadata {
          siteUrl
        }
      }
      pageData: wpPage(slug: { eq: "facial-aesthetics-treatments" }) {
        slug
        seoFieldGroups {
          metaTitle
          metaDescription
          localBusinessSchema
          openGraphDescription
          openGraphTitle
          productSchema
          image {
            node {
              altText
              publicUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                  original {
                    width
                    height
                  }
                }
              }
            }
          }
        }
        facialAestheticsPageFieldGroups {
          facialAestheticsIntroSection {
            heading
            subHeading
            description
            treatments {
              heading
              description
              cta {
                title
                target
                url
              }
              image {
                node {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP]
                        quality: 100
                        transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                        layout: CONSTRAINED
                        placeholder: BLURRED
                      )
                      original {
                        width
                        height
                      }
                    }
                  }
                }
              }
              overlayHeading
              overlaySubHeading
              overlayDescription
              overlayCta {
                title
                target
                url
              }
            }
          }
          facialAestheticsContactSection {
            heading
            content
            formButtonLabel
          }
          facialAestheticsAboveFooterImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                  original {
                    width
                    height
                  }
                }
              }
            }
          }
          facialAestheticsAboveFooterImage2 {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                  original {
                    width
                    height
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const {
    pageData: { seoFieldGroups, facialAestheticsPageFieldGroups, slug },
  } = data;

  const {
    facialAestheticsIntroSection,
    facialAestheticsContactSection,
    facialAestheticsAboveFooterImage,
    facialAestheticsAboveFooterImage2,
  } = facialAestheticsPageFieldGroups;

  const siteUrl = data.site.siteMetadata.siteUrl;

  const serviceSchema = {
    "@context": "https://schema.org/",
    "@type": "Product",
    "@id": `https://www.dentalhousepinner.co.uk/treatments/${slug}/#${slug}`,
    image: `https://www.dentalhousepinner.co.uk${seoFieldGroups?.image?.node.localFile.publicURL}`,
    mpn: `https://www.dentalhousepinner.co.uk/treatments/${slug}`,
    sku: `https://www.dentalhousepinner.co.uk/treatments/${slug}`,
    description: `${seoFieldGroups?.productSchema}`,
    logo: `https://www.dentalhousepinner.co.uk${data.schemaLogo?.localFile.publicURL}`,
    name: "Dental House Pinner",
    url: "https://www.dentalhousepinner.co.uk",
    brand: {
      "@type": "Organization",
      logo: `https://www.dentalhousepinner.co.uk${data.schemaLogo?.localFile.publicURL}`,
      image: `https://www.dentalhousepinner.co.uk${seoFieldGroups?.image?.node.localFile.publicURL}`,
      name: "Dental House Pinner",
      url: "https://www.dentalhousepinner.co.uk",
    },
    aggregateRating: {
      "@type": "AggregateRating",
      reviewCount: `${data.reviews.reviewCount}`,
      ratingValue: "4.9",
      bestRating: "5",
      worstRating: "1",
    },
    review: {
      "@type": "Review",
      url: "https://www.dentalhousepinner.co.uk",
      datePublished: "2023-08-23",
      reviewBody:
        "As someone terrified of dentists, it's been a pleasure being a patient of Dental House. Dr Shah's been a godsend, a truly welcoming presence who never fails to put you at ease. Clearly explains each procedure and how it should feel. The whole team are great!",
      author: {
        "@type": "Person",
        name: "N R",
      },
      reviewRating: {
        "@type": "Rating",
        ratingValue: "5",
        bestRating: "5",
        worstRating: "1",
      },
      publisher: {
        "@type": "Organization",
        name: "Dental House Pinner",
        sameAs: "https://www.dentalhousepinner.co.uk",
      },
    },
  };

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "Treatments",
        item: {
          url: `${siteUrl}/treatments`,
          id: `${siteUrl}/treatments`,
        },
      },
      {
        "@type": "ListItem",
        position: "3",
        name: "Facial Aesthetics Treatments",
        item: {
          url: `${siteUrl}/treatments/facial-aesthetics-treatments`,
          id: `${siteUrl}/treatments/facial-aesthetics-treatments`,
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(serviceSchema)}
        </script>
      </Helmet>
      <GatsbySeo
        title={seoFieldGroups?.metaTitle}
        description={seoFieldGroups?.metaDescription}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}/treatments/facial-aesthetics-treatments`,
          title: `${seoFieldGroups?.openGraphTitle}`,
          description: `${seoFieldGroups?.openGraphDescription}`,
          images: [
            {
              url: `${seoFieldGroups?.image?.node.localFile.publicURL}`,
              width: `${seoFieldGroups?.image?.node.localFile.childImageSharp.original.width}`,
              height: `${seoFieldGroups?.image?.node.localFile.childImageSharp.original.height}`,
              alt: `${seoFieldGroups?.image?.node.altText}`,
            },
          ],
        }}
      />

      <Layout>
        {facialAestheticsIntroSection &&
          !checkPropertiesForNull(facialAestheticsIntroSection, [
            "heading",
          ]) && (
            <section>
              <Container>
                <Row>
                  <Col className="text-center py-5 py-lg-7 ">
                    <p className="text-text-secondary jost-bold fs-6">
                      {facialAestheticsIntroSection?.subHeading}
                    </p>
                    <h1 className=" display-4 pb-5">
                      {facialAestheticsIntroSection?.heading}
                    </h1>
                    <SafeHtmlParser
                      htmlContent={facialAestheticsIntroSection?.description}
                    />
                  </Col>
                </Row>
              </Container>
            </section>
          )}
        {facialAestheticsIntroSection &&
          facialAestheticsIntroSection.treatments &&
          facialAestheticsIntroSection.treatments.length > 0 && (
            <section className="pb-lg-7 pb-5">
              <Container>
                <Row className="justify-content-lg-center g-5 g-lg-7 treatment-wrapper">
                  {facialAestheticsIntroSection.treatments.map((cardItem) => (
                    <CardFacialNew
                      key={uuid4()}
                      secondaryHeading={cardItem?.overlayHeading}
                      secondarySubheading={cardItem?.overlaySubHeading}
                      secondaryText={
                        <SafeHtmlParser
                          htmlContent={cardItem?.overlayDescription}
                        />
                      }
                      idAnim={cardItem?.cta.url}
                      objPos={"top center"}
                      span={5}
                      imageHeight="250px"
                      headingHeight="83px"
                      image={
                        cardItem?.image.node?.localFile.childImageSharp
                          .gatsbyImageData
                      }
                      imgAlt={cardItem.image.node?.altText}
                      title={<SafeHtmlParser htmlContent={cardItem?.heading} />}
                      text={
                        <SafeHtmlParser htmlContent={cardItem?.description} />
                      }
                      bgColour="bg-primary"
                      url={cardItem?.overlayCta.url}
                      viewPageBtnText={cardItem?.overlayCta?.title}
                      buttonText={cardItem?.cta.title}
                    />
                  ))}
                </Row>
              </Container>
            </section>
          )}

        {facialAestheticsContactSection &&
          !checkPropertiesForNull(facialAestheticsContactSection, [
            "heading",
          ]) && (
            <section className="py-5 py-lg-7">
              <Container>
                <Row>
                  <Col lg={6} className="text-center text-lg-start">
                    <h2 className="text-center text-lg-start display-4 pb-4">
                      <SafeHtmlParser
                        htmlContent={facialAestheticsContactSection?.heading}
                      />
                    </h2>

                    <div className="pb-4 text-start pe-xl-10">
                      <SafeHtmlParser
                        htmlContent={facialAestheticsContactSection?.content}
                      />
                    </div>
                  </Col>
                  <Col className="" lg={6}>
                    <ContactForm
                      buttonText={
                        facialAestheticsContactSection?.formButtonLabel
                      }
                    />
                  </Col>
                </Row>
              </Container>
            </section>
          )}

        {/* <BgImage
					style={{ height: "60vh", backgroundPosition: "top" }}
					className="w-100 "
					image={mainImg}
					alt={data.mainImg?.altText}
				/> */}
        <section
          style={{ height: "80vh" }}
          className="position-relative w-100 d-none d-lg-block"
        >
          <div className="h-100 position-absolute start-0 main-image-left">
            {facialAestheticsAboveFooterImage && (
              <GatsbyImage
                className="h-100 w-100"
                image={
                  facialAestheticsAboveFooterImage?.node?.localFile
                    .childImageSharp.gatsbyImageData
                }
                alt={facialAestheticsAboveFooterImage.node?.altText}
              />
            )}
          </div>
          <div className="h-100 position-absolute end-0 main-image-right">
            {facialAestheticsAboveFooterImage2 && (
              <GatsbyImage
                className="h-100 w-100"
                image={
                  facialAestheticsAboveFooterImage2?.node?.localFile
                    .childImageSharp.gatsbyImageData
                }
                alt={facialAestheticsAboveFooterImage2.node?.altText}
              />
            )}
          </div>
        </section>
      </Layout>
    </>
  );
};

export default FacialAestheticsTreatmentsPage;
